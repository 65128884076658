import menus from "./apis/menuApi";
import checkouts from "./apis/checkoutApi";
import stores from "./apis/storeApi";
import members from "./apis/memberApi";
import orders from "./apis/orderApi";
import phones from "./apis/phoneApi";
import foods from "./apis/foodApi";
import historyOrders from "./apis/historyOrderApi";
import feedbacks from "./apis/feedbackApi";
import customers from "./apis/customerApi";
import inStore from "./apis/inStoreApi";
import devices from "./apis/deviceApi";

export default {
  menus,
  checkouts,
  stores,
  members,
  orders,
  phones,
  foods,
  historyOrders,
  feedbacks,
  customers,
  inStore,
  devices,
};
