<template>
  <div class="member-edit">
    <div class="fix-header">
      <a class="fix-header-back-icon" @click="closed">
        <i class="fas fa-chevron-left"></i>
      </a>
      <p class="fix-header-title">{{ $t("feedback.reportissue") }}</p>
    </div>

    <form class="content" @submit.prevent="saved">
      <div class="input-inline-group">
        <textarea
          class="checkout-memo-input"
          id="feedback"
          rows="3"
          v-model="feedback"
          :placeholder="$t('feedback.pleaseentertheissueyouwanttoreport')"
        ></textarea>
      </div>
      <div class="pt-3">
        <button type="submit" class="btn btn-full btn-border">
          {{ $t("feedback.submit") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      feedback: "",
    };
  },
  methods: {
    saved() {
      const vm = this;
      this.$api.feedbacks
        .post(this.feedback)
        .then(() => {
          vm.$store.dispatch(
            "showMessage",
            `${this.$t("feedback.thanksforyourreport")}`
          );
          // let inStore = "";
          // if (vm.$cookie.table.get(vm.$route.params.keyid)) {
          //   inStore = "InStore";
          // }
          // vm.$router.push({ name: inStore + "Menu" });
          vm.closed();
        })
        .catch((error) => vm.$store.dispatch("showMessage", error));
    },
    closed() {
      this.$router.push({
        name: "Member",
      });
    },
  },
  created() {
    this.$store.dispatch("updateLoading", false);
  },
};
</script>
