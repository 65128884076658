<template>
  <div class="member-edit">
    <div class="fix-header">
      <a class="fix-header-back-icon" @click="closed">
        <i class="fas fa-chevron-left"></i>
      </a>
      <p class="fix-header-title">{{ $t("changePassword.changepassword") }}</p>
    </div>

    <form class="content" @submit.prevent="saved">
      <div class="form-group">
        <label for="oldPassword">{{ $t("changePassword.oldpassword") }}</label>
        <input
          id="oldPassword"
          class="form-control"
          type="password"
          v-model="oldPassword"
          required
        />
      </div>
      <div class="form-group">
        <label for="newPassword">{{ $t("changePassword.newpassword") }}</label>
        <input
          id="newPassword"
          class="form-control"
          type="password"
          v-model="newPassword"
          required
        />
      </div>
      <div class="form-group">
        <label for="checkPassword">{{
          $t("changePassword.enterpasswordagain")
        }}</label>
        <input
          id="checkPassword"
          class="form-control"
          type="password"
          v-model="checkPassword"
          required
        />
      </div>
      <div class="pt-3">
        <button type="submit" class="btn btn-full btn-border">
          {{ $t("changePassword.save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      checkPassword: "",
    };
  },
  methods: {
    saved() {
      if (this.checkPassword != this.newPassword) {
        this.$swal(`${this.$t("changePassword.pleaseentersamepassword")}`);
        return;
      }

      let dTO = {
        OldPassword: this.$utils.crypto.encrypt(this.oldPassword),
        NewPassword: this.$utils.crypto.encrypt(this.newPassword),
      };

      const vm = this;
      this.$api.members
        .changePassword(dTO)
        .then(() => {
          vm.$swal({
            titleText: this.$t("changePassword.changesuccess"),
            icon: "success",
          });
          vm.closed();
        })
        .catch((error) => vm.$swal(error.data.Message));
    },
    closed() {
      this.$router.push({
        name: "Member",
      });
    },
  },
  mounted() {
    this.member = this.$cookie.member.get();
  },
  created() {
    this.$store.dispatch("updateLoading", false);
  },
};
</script>
