import { httpGet, httpPost } from "@/requests/http";

let path = "/instore";
const url = process.env.VUE_APP_API_URL;

export default {
  get(keyid, salTempId) {
    return httpGet(`${url}/${keyid}${path}/${salTempId}`);
  },
  getItems(keyid, salTempId) {
    return httpGet(`${url}/${keyid}${path}/${salTempId}/items`);
  },
  postNewTable(keyid, partId) {
    return httpPost(`${url}/${keyid}${path}/tables/${partId}/salTemps`);
  },
  getLastTable(keyid, partId, salTempId) {
    return httpGet(
      `${url}/${keyid}${path}/tables/${partId}/salTemps/${salTempId}`
    );
  },
};
