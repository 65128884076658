import { httpGet } from "@/requests/http";

let path = "/devices";
const url = process.env.VUE_APP_API_TEST_URL;

export default {
  getLineInfo(dTO) {
    return httpGet(`${url}${path}/lineinfo`, dTO);
  },
};
