export function getAll() {
  return [
    {
      value: 0,
      text: "中文 (ZH)",
      name: "Chinese",
      lang: "ZH",
    },
    {
      value: 1,
      text: "English (EN)",
      name: "English",
      lang: "EN",
    },
    {
      value: 2,
      text: "日本語 (JP)",
      name: "Japanese",
      lang: "JP",
    },
    {
      value: 3,
      text: "한국어 (KR)",
      name: "Korean",
      lang: "KR",
    },
    {
      value: 4,
      text: "Français (FR)",
      name: "French",
      lang: "FR",
    },
  ];
}

export function getByLang(lang) {
  const type = getAll().find(function (item) {
    return item.lang.toLowerCase() == lang.toLowerCase();
  });
  return type;
}
