import store from "../store/index";

export default {
  set(id, item) {
    store.dispatch("setDeviceId", item.DeviceId);
    window.localStorage[`store.${id}`] = JSON.stringify(item);
  },
  get(id) {
    let item = window.localStorage[`store.${id}`];
    if (!item) {
      return undefined;
    }
    return JSON.parse(item);
  },
};
