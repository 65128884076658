import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "Sunday",
      cht: "星期日",
      text: `${i18n.t("week.sunday")}`,
    },
    {
      value: 0,
      name: "Monday",
      cht: "星期一",
      text: `${i18n.t("week.monday")}`,
    },
    {
      value: 0,
      name: "Tuesday",
      cht: "星期二",
      text: `${i18n.t("week.tuesday")}`,
    },
    {
      value: 0,
      name: "Wednesday",
      cht: "星期三",
      text: `${i18n.t("week.wednesday")}`,
    },
    {
      value: 0,
      name: "Thursday",
      cht: "星期四",
      text: `${i18n.t("week.thursday")}`,
    },
    {
      value: 0,
      name: "Friday",
      cht: "星期五",
      text: `${i18n.t("week.friday")}`,
    },
    {
      value: 0,
      name: "Saturday",
      cht: "星期六",
      text: `${i18n.t("week.saturday")}`,
    },
  ];
}

export function getByCht(cht) {
  const type = getAll().find(function (item) {
    return item.cht == cht;
  });
  return type;
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name == name;
  });
  return type;
}
