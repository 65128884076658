<template>
  <div class="login">
    <div class="fix-header">
      <a class="fix-header-back-icon" @click="closed(false)">
        <i class="fas fa-chevron-left"></i>
      </a>
      <p class="fix-header-title">{{ $t("login.login") }}</p>
    </div>
    <form class="content" @submit.prevent="saved">
      <div class="input-button-group mb-2">
        <div class="cancel-area"></div>
        <div class="country-code-container" @click="openCountryList">
          <input
            class="country-code-input"
            aria-hidden="true"
            v-model="currentCountryCode"
          />
          <div class="country-code-text">+{{ currentCountryCode }}</div>
          <i class="fas fa-caret-down"></i>
          <ul class="country-list">
            <li
              v-for="countryCode in countryCodes"
              :key="countryCode.value"
              :data-value="countryCode.value"
              class="country"
              :class="[countryCode.value == 886 ? 'active' : '']"
              @click="selectCountry"
            >
              {{ `${countryCode.text}(+${countryCode.value})` }}
            </li>
          </ul>
        </div>
        <input
          class="cellphone-input"
          type="tel"
          v-model="login.Account"
          :placeholder="$t('login.phonenumber')"
          required
        />
      </div>
      <div class="relative mb-2">
        <input
          class="input-1 inline-block m-0"
          :type="passwordType"
          v-model="login.Password"
          :placeholder="$t('login.password')"
          required
        />
        <a class="btn-right" @click="passwordEyeClick" v-if="isPassword">
          <div>
            <i class="fas fa-eye-slash"></i>
          </div>
        </a>
        <a class="btn-right" @click="passwordEyeClick" v-else>
          <div><i class="fas fa-eye"></i></div
        ></a>
      </div>

      <button type="submit" class="btn btn-full btn-border my-3">
        {{ $t("login.login") }}
      </button>
      <!-- <lineLoginButton></lineLoginButton> -->

      <div class="login-links">
        <a @click="goForgetPassword" class="bold">{{
          $t("login.forgotpassword")
        }}</a>
        <a @click="goRegister" class="bold">{{ $t("login.register") }}</a>
      </div>
    </form>
  </div>
</template>

<script>
import liff from "@line/liff";
// import lineLoginButton from "@/components/LineLoginButton";

export default {
  data() {
    return {
      login: {},
      countryCodes: this.$enum.countryCodeType.getAll(),
      passwordType: "password",
      currentCountryCode: 886,
      currentStore: null,
      isLineLiff: false,
    };
  },
  components: {
    // lineLoginButton,
  },
  computed: {
    isPassword() {
      return this.passwordType == "password";
    },
  },
  methods: {
    addCancelArea() {
      const cancelArea = document.querySelector(".cancel-area");
      cancelArea.style.display = "block";
      document.addEventListener("click", this.removeCancelArea);
    },
    removeCancelArea() {
      const countryList = document.querySelector(".country-list");
      const cancelArea = document.querySelector(".cancel-area");
      countryList.classList.remove("active");
      cancelArea.style.display = "none";
      document.removeEventListener("click", this.removeCancelArea);
    },
    openCountryList(e) {
      e.stopPropagation();
      const countryList = document.querySelector(".country-list");
      const cancelArea = document.querySelector(".cancel-area");
      countryList.classList.add("active");
      cancelArea.style.display = "block";
      this.addCancelArea();
    },
    selectCountry(e) {
      e.stopPropagation();
      const countries = document.querySelectorAll(".country");
      countries.forEach((c) => {
        c.classList.remove("active");
      });
      this.currentCountryCode = e.target.dataset.value;
      e.target.classList.add("active");
      this.removeCancelArea();
    },
    saved() {
      this.login.CountryCode = this.currentCountryCode;
      let ob = { ...this.login };
      ob.Password = this.$utils.crypto.encrypt(ob.Password);
      const vm = this;
      this.$api.members
        .login(ob)
        .then((data) => {
          vm.$cookie.member.set(data);
          if (vm.isLineLiff) {
            vm.$api.members
              .bindLineUserId(
                vm.currentStore.DeviceId,
                vm.$store.state.lineUserId
              )
              .then(() => {
                vm.closed(true);
              })
              .catch((error) => {
                vm.$swal(error.data.Message);
                vm.closed(true);
              });
          } else {
            vm.closed(true);
          }
        })
        .catch((error) => {
          vm.$swal(error.data.Message);
        });
    },
    closed(isSave) {
      let routeData = this.$func.getRoute();
      if (this.$store.state.pathName == "Checkout") {
        if (isSave) {
          this.$router.push({
            name: routeData.InStore + "Checkout",
            params: {
              routeid: routeData.RouteId,
            },
          });
        } else {
          this.$router.push({
            name: routeData.InStore + "Menu",
            params: {
              routeid: routeData.RouteId,
            },
          });
        }
      } else {
        this.$router.push({
          name: routeData.InStore + "Menu",
          params: {
            routeid: routeData.RouteId,
          },
        });
      }
    },
    goRegister() {
      this.$router.push({
        name: "Register",
      });
    },
    goForgetPassword() {
      this.$router.push({
        name: "ForgetPassword",
      });
    },
    passwordEyeClick() {
      if (this.isPassword) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
  },
  mounted() {
    const vm = this;
    if (liff.isInClient()) {
      this.isLineLiff = true;
      this.currentStore = this.$cookie.store.get(this.$store.state.keyId);
      liff.init({ liffId: this.currentStore.LineLiffId }).then(() => {
        liff.getProfile().then((data) => {
          vm.$store.dispatch("setLineUserId", data.userId);
          vm.$api.members
            .searchLineUserId(
              vm.currentStore.DeviceId,
              vm.$store.state.lineUserId
            )
            .then((data) => {
              vm.$cookie.member.set(data);
              vm.$cookie.customer.set(data);
              vm.$store.dispatch("updateTime", false);
              vm.$router.push({
                name: "Menu",
                params: { keyid: vm.$store.state.keyId },
              });
            })
            .catch(() => {
              vm.$store.dispatch("updateLoading", false);
            });
        });
      });
    } else {
      const token = this.$cookie.member.getToken();
      const member = this.$cookie.member.get();
      if (!token || !member) this.$store.dispatch("updateLoading", false);
    }
  },
  beforeCreate() {
    const token = this.$cookie.member.getToken();
    const member = this.$cookie.member.get();
    if (token && member) {
      let routeData = this.$func.getRoute();
      this.$router.push({
        name: routeData.InStore + "Menu",
        params: {
          routeid: routeData.RouteId,
        },
      });
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
