<template>
  <div class="join">
    <div class="fix-header">
      <a class="fix-header-back-icon" @click="closed">
        <i class="fas fa-chevron-left"></i>
      </a>
      <p class="fix-header-title">{{ $t("join.joinstore") }}</p>
    </div>
    <div class="content login-content">
      <p>{{ $t("join.alreadyjoin") }}</p>
      <button class="btn btn-full btn-border my-3" type="button" @click="saved">
        {{ $t("join.join") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    saved() {
      const vm = this;
      this.$api.members
        .join()
        .then(() => {
          vm.closed(true);
        })
        .catch((error) => vm.$swal(error.data.Message));
    },
    closed(isSave) {
      let routeData = this.$func.getRoute();
      if (this.$store.state.pathName == "Checkout") {
        if (isSave) {
          this.$router.push({
            name: routeData.InStore + "Checkout",
            params: {
              routeid: routeData.RouteId,
            },
          });
        } else {
          this.$router.push({
            name: routeData.InStore + "Menu",
            params: {
              routeid: routeData.RouteId,
            },
          });
        }
      } else if (this.$store.state.pathName) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: routeData.InStore + "Menu",
          params: {
            routeid: routeData.RouteId,
          },
        });
      }
    },
  },
  beforeCreate() {
    if (this.$cookie.customer.isStoreCustomer(this.$route.params.keyid)) {
      let routeData = this.$func.getRoute();
      this.$router.push({
        name: routeData.InStore + "Menu",
        params: {
          routeid: routeData.RouteId,
        },
      });
    }
  },
  created() {
    this.$store.dispatch("updateLoading", false);
    window.scrollTo(0, 0);
  },
};
</script>
