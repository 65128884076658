import cart from "./cart";
import member from "./member";
import store from "./store";
import customer from "./customer";
import table from "./table";

export default {
  cart,
  member,
  store,
  customer,
  table,
};
