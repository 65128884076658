<template>
  <div class="combo-taste">
    <div class="combo-taste-content">
      <div class="fix-header">
        <a class="fix-header-back-icon" @click="closed">
          <i class="fas fa-chevron-left"></i>
        </a>
        <p class="fix-header-title">{{ comboGroup.Combo.FoodName }}</p>
      </div>

      <div class="content">
        <order-food-taste
          :foodTasteGroups="comboGroup.FoodTastes"
          :tastes="tastes"
          @tasteChange="tasteChange"
        />
      </div>
      <div class="fill-footer"></div>
      <div class="btn-fix-footer fixed">
        <button class="btn btn-full btn-border" @click="saved">
          <span>{{ $t("order.save") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import OrderFoodTaste from "../components/OrderFoodTaste.vue";
// import $ from "jquery";

export default {
  data() {
    return {
      tastes: [],
    };
  },
  props: ["comboGroup", "selectedCombo"],
  components: {
    OrderFoodTaste,
  },
  methods: {
    tasteChange(tastes) {
      this.tastes = tastes;
    },
    saved(e) {
      let canSave = this.comboGroup.FoodTastes.every((foodTaste) => {
        let selectedTastes = this.tastes.filter((taste) => {
          return foodTaste.TasteKind.TasteKindId == taste.TasteKindId;
        });
        return (
          (foodTaste.MinQty > 0 && selectedTastes.length >= foodTaste.MinQty) ||
          foodTaste.MinQty == 0
        );
      });
      if (e) {
        e.preventDefault();
      }
      if (!canSave) {
        this.$swal(this.$t("order.flavorsnotselected"));
        return;
      }
      if (this.selectedCombo) {
        let combo = Object.assign({}, this.selectedCombo);
        combo.Tastes = this.tastes;
        this.closed();
        this.$emit("edit", combo);
      } else {
        let combo = Object.assign({}, this.comboGroup.Combo);
        combo.Tastes = this.tastes;
        this.closed();
        this.$emit("save", combo);
      }
    },
    closed(e) {
      if (e) {
        e.preventDefault();
      }
      this.$emit("close", this.comboGroup);
    },
  },
  mounted() {
    let group = this.comboGroup;
    if (this.selectedCombo) {
      this.selectedCombo.Tastes.forEach((taste) => {
        this.tastes.push(taste);
      });
    } else {
      group.FoodTastes.forEach((foodTasteGroup) => {
        let tasteIndex = foodTasteGroup.Tastes.findIndex((taste) => {
          return taste.IsSelect;
        });
        if (tasteIndex > -1) {
          this.tastes.push(foodTasteGroup.Tastes[tasteIndex]);
        }
      });
    }
  },
};
</script>
