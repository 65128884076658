/**
 * Deep clone an object or array
 * @param {Object|Array} obj - The item to be cloned
 * @returns {Object|Array} - The cloned item
 */
export default function clone(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(clone);
  }

  const clonedObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = clone(obj[key]);
    }
  }
  return clonedObj;
}
