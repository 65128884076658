import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    keyId: "",
    pathName: "",
    isFirstTime: true,
    lineUserId: "",
    isInStore: false,
    deviceId: "",
    lineResponse: {},
    currentLang: window.localStorage["lang"] || "tw",
  },
  mutations: {
    ISLOADING(state, status) {
      state.isLoading = status;
    },
    KEYID(state, id) {
      state.keyId = id;
    },
    PATH(state, name) {
      state.pathName = name;
    },
    ISFIRSTTIME(state, value) {
      state.isFirstTime = value;
    },
    LINEUSERID(state, id) {
      state.lineUserId = id;
    },
    ISINSTORE(state, value) {
      state.isInStore = value;
    },
    DEVICEID(state, id) {
      state.deviceId = id;
    },
    LINERESPONSE(state, obj) {
      state.lineResponse = obj;
    },
    LANGUAGE(state, lang) {
      state.currentLang = lang;
    },
  },
  actions: {
    updateLoading(context, status) {
      context.commit("ISLOADING", status);
    },
    setKeyId(context, id) {
      context.commit("KEYID", id);
    },
    setPATH(context, name) {
      context.commit("PATH", name);
    },
    updateTime(context, value) {
      context.commit("ISFIRSTTIME", value);
    },
    setLineUserId(context, id) {
      context.commit("LINEUSERID", id);
    },
    saveInStore(context, value) {
      context.commit("ISINSTORE", value);
    },
    setDeviceId(context, id) {
      context.commit("DEVICEID", id);
    },
    saveLineResponse(context, obj) {
      context.commit("LINERESPONSE", obj);
    },
    setLanguage(context, status) {
      context.commit("LANGUAGE", status);
    },
  },
  modules: {},
});
