import zh from "./zh";
import en from "./en";
import jp from "./jp";
import kr from "./kr";
import fr from "./fr";

export default {
  zh,
  en,
  jp,
  kr,
  fr,
};
