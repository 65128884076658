export default {
  set(id, customer) {
    if (customer) {
      window.localStorage[`customer.${id}`] = JSON.stringify({
        Name: customer.Name,
      });
    }
  },
  get(id) {
    let customer = window.localStorage[`customer.${id}`];
    if (!customer) {
      return undefined;
    }
    return JSON.parse(customer);
  },
  clearId(id) {
    window.localStorage.removeItem(`customer.${id}`);
  },
  isStoreCustomer(id) {
    const customer = this.get(id);
    if (customer && customer.Name) return true;
    else return false;
  },
};
