import { httpPostWithKey } from "@/requests/http";

let path = "/checkouts";

export default {
  post(trade) {
    return httpPostWithKey(path, trade);
  },
  postInStore(salTempId, trade) {
    return httpPostWithKey(`${path}/${salTempId}/instore`, trade);
  },
};
