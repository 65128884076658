<template>
  <div class="invalid-container">
    <img class="invalid-img" src="../imgs/invalid.png" alt="invalid" />
    <div class="invalid-text">{{ $t("error.urlexpired") }}</div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("updateLoading", false);
  },
};
</script>

<style lang="scss">
.invalid-container {
  width: 100%;
  height: calc(100vh - 99px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .invalid-img {
    width: 180px;
    padding: 20px;
  }
  .invalid-text {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 50px;
  }
}
</style>
