<template>
  <div class="member">
    <div class="member-header">
      <a class="member-back-icon" @click="closed">
        <i class="fas fa-chevron-left"></i>
      </a>
    </div>
    <div class="member-container">
      <div class="member-info">
        <label for="member-img">
          <input id="member-img" type="file" @change="saveImage" hidden />

          <div v-if="img" style="position: relative">
            <img class="member-img" :src="img" alt="會員" />
            <a class="member-img-delete" @click.prevent="removeImage">
              <i class="fas fa-times-circle"></i
            ></a>
          </div>
          <div v-else>
            <img class="member-img" src="../imgs/user1.png" alt="" />
          </div>
        </label>
        <p class="member-name">{{ member.Name }}</p>
        <p class="member-phone">{{ member.Cellphone }}</p>
      </div>

      <ul>
        <li @click="information">
          <div class="member-list-text">
            <div class="member-list-icon">
              <i class="fas fa-user menu-member-icon"></i>
            </div>
            <p>{{ $t("member.profile") }}</p>
          </div>
          <i class="fas fa-chevron-right"></i>
        </li>
        <li @click="orderQuery">
          <div class="member-list-text">
            <div class="member-list-icon">
              <i class="fas fa-file-invoice-dollar"></i>
            </div>
            <p>{{ $t("member.historyorder") }}</p>
          </div>
          <i class="fas fa-chevron-right"></i>
        </li>
        <!-- <li>
          <div>
            <i class="fas fa-tag member-function-icon"></i>
            <span>優惠券</span>
          </div>
          <i class="fas fa-chevron-right"></i>
        </li> -->
        <li @click="editPassword">
          <div class="member-list-text">
            <div class="member-list-icon">
              <i class="fas fa-lock"></i>
            </div>
            <p>{{ $t("member.changepassword") }}</p>
          </div>
          <i class="fas fa-chevron-right"></i>
        </li>
        <li @click="feedback">
          <div class="member-list-text">
            <div class="member-list-icon">
              <i class="fas fa-exclamation"></i>
            </div>
            <p>{{ $t("member.reportissue") }}</p>
          </div>
          <i class="fas fa-chevron-right"></i>
        </li>
      </ul>
      <button class="btn btn-full btn-border" @click="logout">
        {{ $t("member.logout") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: "",
      member: {},
    };
  },
  methods: {
    orderQuery() {
      this.$router.push({
        name: "HistoryOrders",
      });
    },
    information() {
      this.$router.push({
        name: "MemberEdit",
      });
    },
    editPassword() {
      this.$router.push({
        name: "PasswordEdit",
      });
    },
    logout() {
      this.$cookie.member.clearId();
      this.$cookie.member.remove();
      this.$store.dispatch("logout");
      this.closed();
    },
    closed() {
      let routeData = this.$func.getRoute();
      this.$router.push({
        name: routeData.InStore + "Menu",
        params: { routeid: routeData.RouteId },
      });
    },
    saveImage(e) {
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.img = reader.result;
      };
      let formData = new FormData();
      formData.append("file", file);
      this.$api.members.saveImage(formData).then(() => {});
    },
    removeImage() {
      if (confirm("確定刪除嗎 ?")) {
        const vm = this;
        this.$api.members.removeImage().then(() => {
          vm.img = "";
        });
      }
    },
    feedback() {
      this.$router.push({
        name: "Feedback",
      });
    },
  },
  mounted() {
    this.member = this.$cookie.member.get();
    this.img = "";
    const vm = this;
    if (this.$cookie.member.getToken()) {
      this.$api.members.getImage().then((data) => {
        vm.$store.dispatch("updateLoading", false);
        if (data.size > 0) {
          let reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            vm.img = reader.result;
          };
        }
      });
    } else {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>
