import getDate from "./getDate";
import getTime from "./getTime";
import getRoute from "./getRoute";
import deepClone from "./deepClone";

export default {
  getDate,
  getTime,
  getRoute,
  deepClone,
};
