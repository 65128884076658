export default function () {
  let inStore = "";
  let routeId = "";
  if (localStorage.getItem("salId")) {
    inStore = "InStore";
    routeId = localStorage.getItem("salId");
  }
  if (localStorage.getItem("salTempId")) {
    inStore = "InStoreTable";
    routeId = localStorage.getItem("tableMapId");
  }
  return { InStore: inStore, RouteId: routeId };
}
