<template>
  <div>
    <div
      class="order-group"
      v-for="foodTasteGroup in foodTasteGroups"
      :key="foodTasteGroup.TasteKind.TasteKinId"
    >
      <div class="order-group-header">
        <div class="flex space-between order-group-kind-name">
          <div class="order-group-title">
            {{ foodTasteGroup.TasteKind.TasteKindName }}
          </div>
          <div class="order-group-min" v-if="foodTasteGroup.MinQty > 0">
            <p>{{ $t("order.required") }}{{ foodTasteGroup.MinQty }}</p>
          </div>
        </div>
        <p class="order-group-max" v-if="foodTasteGroup.MaxQty > 0">
          {{ $t("order.available") }}{{ foodTasteGroup.MaxQty }}
        </p>
      </div>
      <div class="order-group-content">
        <label
          class="order-item"
          v-for="taste in foodTasteGroup.Tastes"
          :key="taste.TasteId"
          :for="taste.TasteId"
        >
          <div class="flex space-between items-center">
            <div class="order-item-text flex items-center">
              <input
                type="checkbox"
                :id="taste.TasteId"
                :value="taste"
                v-model="values"
                @click="tasteClick(taste, $event)"
                @change="tasteChange"
              />
              <label :for="taste.TasteId">{{ taste.TasteName }}</label>
            </div>
            <p v-if="taste.Price != 0">+ ${{ taste.Price }}</p>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      values: [],
    };
  },
  props: ["isEdit", "foodTasteGroups", "tastes", "trigger"],
  watch: {
    trigger(value) {
      // window.scrollTo(0, 0);
      if (!value) {
        this.values = [];
      }
    },
    foodTasteGroups(value) {
      let groups = value;
      groups.forEach((foodTasteGroup) => {
        let tasteIndex = foodTasteGroup.Tastes.findIndex((taste) => {
          return taste.IsSelect;
        });
        if (tasteIndex > -1) {
          this.values.push(foodTasteGroup.Tastes[tasteIndex]);
        }
      });
      if (!this.isEdit) {
        this.tasteChange();
      }
    },
    tastes(newValue) {
      this.values = newValue;
    },
  },
  methods: {
    tasteClick(taste, e) {
      let index = this.tastes.findIndex((det) => {
        return det.TasteId == taste.TasteId;
      });
      if (index >= 0) {
        return;
      }
      const foodTaste = this.foodTasteGroups.find((det) => {
        return det.TasteKind.TasteKindId == taste.TasteKindId;
      });
      if (foodTaste.MaxQty > 1) {
        let i = 0;
        this.tastes.forEach((det) => {
          if (det.TasteKindId == taste.TasteKindId) {
            i = i + 1;
          }
        });
        if (i >= foodTaste.MaxQty) {
          e.preventDefault();
          this.$swal(this.$t("order.exceedsselectionlimit"));
          return;
        }
      } else if (foodTaste.MaxQty == 1) {
        let removeIndex = this.tastes.findIndex((t) => {
          return t.TasteKindId == taste.TasteKindId;
        });
        if (removeIndex > -1) {
          this.tastes.splice(removeIndex, 1);
        }
      }
    },
    tasteChange() {
      this.$emit("tasteChange", this.values);
    },
  },
};
</script>
