<template>
  <div class="error">
    <img class="img" src="../imgs/Error.png" />
    <div class="word row">
      <div class="col-9">
        <h1>Oops!</h1>
        <h4>{{ $t("error.sorrymessage") }}</h4>
      </div>
      <div class="col-auto">
        <button class="btn btn-lg" type="button" @click="onClicked">
          <i class="fas fa-undo-alt"></i>
          {{ $t("error.goback") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClicked() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  position: fixed;
  width: 100%;
  height: 100%;
}
.img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100px;
  height: 400px;
}
.word {
  width: 100%;
  position: absolute;
  top: 500px;
  margin: 30px 50px;
  h1 {
    font-weight: bold;
    color: #ff8484;
  }
  h4 {
    font-weight: bold;
    color: #707070;
  }
}
.btn {
  font-weight: bold;
  color: white;
  background-color: #ff8484;
  margin-top: 25px;
  padding: 10px 35px;
  border-radius: 25px;
}

// @media only screen and (max-width: 768px) {
//   .img{
//     height: 200px;
//   }
// }
</style>
