export default {
  set(storeId) {
    localStorage.setItem("tableMapId", storeId);
  },
  get() {
    let store = localStorage.getItem("tableMapId");
    if (!store) {
      return undefined;
    }
    return store;
  },
  remove() {
    localStorage.removeItem("tableMapId");
  },
};
