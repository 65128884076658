<template>
  <div class="wrap">
    <div class="store">
      <div
        class="store-block"
        v-for="device in devices"
        :key="device.DeviceId"
        @click="storeClick(device)"
      >
        <div class="store-img">
          <img :src="device.ImageURL" alt="" v-if="device.ImageURL" />

          <div class="image-empty" v-else>
            <img src="../imgs/store.png" alt="" />
          </div>
        </div>
        <div class="store-text">
          <p class="store-name">{{ device.StoreName }}</p>
          <p class="store-address">{{ device.Address }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { devices: [] };
  },
  mounted() {
    let url = new URL(decodeURIComponent(window.location.href));
    let param = url.searchParams;
    if (param.has("liff.state")) {
      return;
    }
    const vm = this;
    this.$api.stores.get().then((data) => {
      vm.$store.dispatch("updateLoading", false);
      vm.devices = data;
    });
  },
  methods: {
    storeClick(store) {
      this.$router.push({
        name: "Menu",
        params: { keyid: store.DeviceId },
      });
    },
  },
};
</script>
