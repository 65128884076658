<template>
  <div class="order">
    <div class="time-header" style="padding: 20px 0px">
      <div
        class="pointer"
        style="display: flex; justify-content: start; align-items: center"
        @click="closed"
      >
        <span
          style="
            margin-left: 10px;
            padding: 0px 15px;
            font-weight: 100;
            font-size: 20px;
            vertical-align: middle;
          "
        >
          <i class="fas fa-chevron-left"></i>
        </span>
        <span style="margin-left: 20px; font-size: 22px; font-weight: bold">
          {{ $t("takeAwayTime.expectedpickuptime") }}
        </span>
      </div>
    </div>
    <hr
      class="m-0"
      style="width: 100%; height: 2px; background-color: #f5f5f5"
    />
    <div class="time-body" style="padding: 0px 18px">
      <div class="form-group mb-0">
        <label for="pickUpDate" style="font-weight: bold">{{
          $t("takeAwayTime.pickupdate")
        }}</label>
        <select
          id="pickUpDate"
          class="form-control"
          v-model="selectedDate"
          @change="changeDate(false)"
        >
          <option
            v-for="type in item.dateTypes"
            :key="type.value"
            :value="type.value"
          >
            {{ type.listText }}
          </option>
        </select>
      </div>
      <div
        class="form-group pl-1"
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <date-picker
          input-class="hide-input"
          id="datePicker"
          ref="datePicker"
          value-type="format"
          format="YYYY-MM-DD"
          :default-value="new Date()"
          :disabled-date="disabledDate"
          :clearable="false"
          v-model="dateRange"
          @change="datePickerChange"
        >
        </date-picker>
        <div
          class="pointer date-picker-trigger"
          style="position: relative"
          @click="openDatePicker"
        >
          <i class="fas fa-calendar-alt"></i>
          <span class="ml-1">{{ $t("takeAwayTime.otherdates") }}</span>
        </div>
      </div>
      <div class="form-group">
        <label for="pickUpTime" style="font-weight: bold">{{
          $t("takeAwayTime.pickuptime")
        }}</label>
        <select id="pickUpTime" class="form-control" v-model="selectedTime">
          <option
            v-for="type in item.timeTypes"
            :key="type.value"
            :value="type.value"
            :disabled="type.isOrderFull"
            :style="type.isOrderFull ? 'color: red' : ''"
          >
            {{ type.text }}
          </option>
        </select>
      </div>
    </div>
    <div class="time-footer"></div>
    <div
      class="order-footer-container"
      style="position: absolute; bottom: 0; width: 100%"
    >
      <div class="order-footer" style="margin-top: 0">
        <button class="btn btn-border" style="width: 100%" @click="saved">
          {{ $t("takeAwayTime.confirm") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  data() {
    return {
      dateRange: {},
      selectedDate: 0,
      selectedTime: 0,
      item: {
        dateTypes: [],
        timeTypes: [],
        currentNum: 0,
      },
      weeks: this.$enum.weekType.getAll(),
      workDays: [],
      selectedWorkDay: [],
      workTimes: [],
    };
  },
  props: [
    "takeTime",
    "workDaysArr",
    "trigger",
    "waitingSetting",
    "defaultDate",
    "defaultTime",
    "hasIntervalCounts",
  ],
  components: {
    DatePicker,
  },
  watch: {
    trigger(value) {
      if (value) {
        this.item = { ...this.takeTime };
        this.workDays = this.workDaysArr;
        this.selectedDate = this.defaultDate.value;
        this.selectedTime = this.defaultTime.value;
        this.dateRange = this.defaultDate.text;
        let selected = this.item.dateTypes.find((date) => {
          return date.text == this.dateRange;
        });
        if (selected) {
          this.selectedDate = selected.value;
        } else {
          let newDate = new Date(this.dateRange);
          let newDateType = {
            day: {
              value: newDate.getDay(),
              text: `${this.weeks[newDate.getDay()].cht}`,
              word: `${this.weeks[newDate.getDay()].text}`,
            },
            listText: this.dateRange,
            text: this.dateRange,
            value:
              this.item.dateTypes[this.item.dateTypes.length - 1].value + 1,
          };
          this.item.dateTypes.push(newDateType);
          this.selectedDate = newDateType.value;
        }
        this.changeDate(true);
      }
    },
  },
  methods: {
    changeDate(IsOpen) {
      let date = this.item.dateTypes.find((d) => {
        return d.value == this.selectedDate;
      });
      this.dateRange = date.text;
      let currentDate = this.item.dateTypes.find((d) => {
        return d.value == this.selectedDate;
      });
      this.selectedWorkDay = this.workDays.find((d) => {
        return currentDate.day.text == d.Text;
      });
      this.resetTimeTypes(this.selectedWorkDay.Times, IsOpen);
    },
    datePickerChange() {
      let selected = this.item.dateTypes.find((date) => {
        return date.text == this.dateRange;
      });
      if (selected) {
        this.selectedDate = selected.value;
      } else {
        let newDate = new Date(this.dateRange);
        let newDateType = {
          day: {
            value: newDate.getDay(),
            text: `${this.weeks[newDate.getDay()].cht}`,
            word: `${this.weeks[newDate.getDay()].text}`,
          },
          listText: this.dateRange,
          text: this.dateRange,
          value: this.item.dateTypes[this.item.dateTypes.length - 1].value + 1,
        };
        this.item.dateTypes.push(newDateType);
        this.selectedDate = newDateType.value;
      }
      this.changeDate(false);
    },
    openDatePicker() {
      this.$refs.datePicker.openPopup();
    },
    disabledDate(date) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (today.getTime() == date.getTime()) {
        return (
          this.takeTime.dateTypes[0].listText != this.$t("takeAwayTime.today")
        );
      }
      if (
        this.waitingSetting.WaitingType == 2 &&
        (this.waitingSetting.ReserveDays == -1 ||
          this.waitingSetting.ReserveDays >= 1)
      ) {
        if (this.waitingSetting.ReserveDays == -1) {
          return (
            this.workDays[date.getDay()].Times[0] == "公休" ||
            date <=
              new Date(
                today.getTime() +
                  (this.waitingSetting.WaitingNum - 1) * 86400000
              )
          );
        } else {
          return (
            this.workDays[date.getDay()].Times[0] == "公休" ||
            date <=
              new Date(
                today.getTime() +
                  (this.waitingSetting.WaitingNum - 1) * 86400000
              ) ||
            date >
              new Date(
                today.getTime() + this.waitingSetting.ReserveDays * 86400000
              )
          );
        }
      }
      if (this.waitingSetting.ReserveDays == -1) {
        return this.workDays[date.getDay()].Times[0] == "公休" || date < today;
      } else if (this.waitingSetting.ReserveDays == 0) {
        return (
          this.workDays[date.getDay()].Times[0] == "公休" ||
          date < today ||
          date > new Date(today.getTime())
        );
      } else {
        return (
          this.workDays[date.getDay()].Times[0] == "公休" ||
          date < today ||
          date >
            new Date(
              today.getTime() + this.waitingSetting.ReserveDays * 86400000
            )
        );
      }
    },
    resetTimeTypes(times, IsOpen) {
      this.workTimes = [];
      this.item.timeTypes = [];
      if (this.selectedDate > 0) {
        if (!IsOpen) this.selectedTime = 1;
        for (let index = 0; index < times.length; index++) {
          let time = {
            startHr: parseInt(times[index].substring(0, 2)),
            startMin: parseInt(times[index].substring(3, 5)),
            endHr: parseInt(times[index].substring(8, 10)),
            endMin: parseInt(times[index].substring(11, 13)),
          };
          if (time.startHr == time.endHr && time.startMin == time.endMin) {
            time = {
              startHr: 0,
              startMin: 0,
              endHr: 24,
              endMin: 0,
            };
          }
          this.workTimes.push(time);
          this.intervalCalculate(this.workTimes[index], index);
        }
      } else {
        this.item.timeTypes = this.takeTime.timeTypes;
        let dTO = {
          Text: this.item.dateTypes[this.selectedDate].text,
          Times: [],
        };
        this.item.timeTypes.forEach((timeType) => {
          dTO.Times.push(timeType.time.replace(/\s+/g, ""));
        });
        if (this.hasIntervalCounts) {
          if (!IsOpen) this.selectedTime = this.item.timeTypes[0].value;
          this.$api.stores
            .getOrdersStatus(this.$route.params.keyid, dTO)
            .then((data) => {
              let checkIsOrderFull = data;
              this.item.timeTypes.forEach((t, i) => {
                let checkTime = t.time.replace(/\s+/g, "");
                let isOrderFull = checkIsOrderFull.some((p) => {
                  return p.IsOrderFull && p.Time == checkTime;
                });
                if (isOrderFull) {
                  t.isOrderFull = true;
                  if (!t.text.includes(this.$t("takeAwayTime.orderfull")))
                    t.text += this.$t("takeAwayTime.isorderfull");
                }
                if (i == this.item.timeTypes.length - 1) {
                  let currentTimeIndex = this.item.timeTypes.findIndex(
                    (timeType) => {
                      return timeType.value == this.selectedTime;
                    }
                  );
                  if (
                    this.item.timeTypes[currentTimeIndex].text.includes(
                      this.$t("takeAwayTime.orderfull")
                    )
                  ) {
                    let firstSelectable = this.item.timeTypes.find(
                      (timeType) => {
                        return !timeType.text.includes(
                          this.$t("takeAwayTime.orderfull")
                        );
                      }
                    );
                    this.selectedTime = firstSelectable.value;
                  }
                }
              });
            });
        } else {
          this.selectedTime = this.item.timeTypes[0].value;
        }
      }
    },
    intervalCalculate(workTime, index) {
      let midnightCheck =
        workTime.endHr == 24 &&
        this.workDays[
          (this.workDays.indexOf(this.selectedWorkDay) + 1) % 7
        ].Times[0].substring(0, 5) == "00:00";

      for (
        let i = 0;
        i < Math.floor(60 / this.waitingSetting.WaitingInterval) * 24;
        i++
      ) {
        let t = { value: 1, text: "", time: "" };
        if (index > 0) {
          t.value = this.item.timeTypes.length + 1;
        } else {
          t.value = i + 1;
        }
        let hour =
          workTime.startHr +
          Math.floor(
            (this.waitingSetting.WaitingInterval * i + workTime.startMin) / 60
          );
        let minute = 0;
        if (
          (i + 1) * this.waitingSetting.WaitingInterval +
            workTime.startMin / 60 <
          1
        ) {
          minute = i * this.waitingSetting.WaitingInterval + workTime.startMin;
        } else {
          minute =
            (i * this.waitingSetting.WaitingInterval + workTime.startMin) % 60;
        }

        if (
          hour >= 24 ||
          hour > workTime.endHr ||
          (workTime.endMin - this.waitingSetting.LastOrderMinutes < 0 &&
            hour + 1 == workTime.endHr &&
            minute >
              (workTime.endMin - this.waitingSetting.LastOrderMinutes + 60) %
                60 &&
            !midnightCheck) ||
          (hour == workTime.endHr &&
            minute > workTime.endMin - this.waitingSetting.LastOrderMinutes &&
            !midnightCheck)
        ) {
          return;
        } else {
          t.text = `${hour.toString().padStart(2, "0")} : ${minute
            .toString()
            .padStart(2, "0")}`;
          t.time = `${hour.toString().padStart(2, "0")} : ${minute
            .toString()
            .padStart(2, "0")} : 00`;
          this.item.timeTypes.push(t);
        }
      }
    },
    saved() {
      let selectedDate = this.item.dateTypes.find((d) => {
        return d.value == this.selectedDate;
      });

      let selectedTime = {};
      if (this.selectedTime == 0) {
        selectedTime = this.item.timeTypes.find((t) => {
          return t.time;
        });
      } else {
        selectedTime = this.item.timeTypes.find((t) => {
          return t.value == this.selectedTime;
        });
      }

      this.$emit("saved", selectedDate, selectedTime);
    },
    closed() {
      this.$emit("closed");
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
