export function getAll() {
  return [
    {
      value: 886,
      name: "Taiwan",
      text: "台灣",
    },
    {
      value: 81,
      name: "Japan",
      text: "日本",
    },
    {
      value: 82,
      name: "South Korea",
      text: "南韓",
    },
    {
      value: 852,
      name: "Hong Kong",
      text: "香港",
    },
    {
      value: 853,
      name: "Taiwan",
      text: "澳門",
    },
    {
      value: 1,
      name: "United States",
      text: "美國",
    },
    {
      value: 1,
      name: "Canada",
      text: "加拿大",
    },
    {
      value: 44,
      name: "United Kingdom",
      text: "英國",
    },
    {
      value: 93,
      name: "Afghanistan",
      text: "阿富汗",
    },
    {
      value: 54,
      name: "Argentina",
      text: "阿根廷",
    },
    {
      value: 43,
      name: "Austria",
      text: "奧地利",
    },
    {
      value: 61,
      name: "Australia",
      text: "澳大利亞",
    },
    {
      value: 973,
      name: "Bahrain",
      text: "巴林",
    },
    {
      value: 880,
      name: "Bangladesh",
      text: "孟加拉",
    },
    {
      value: 32,
      name: "Belgium",
      text: "比利時",
    },
    {
      value: 975,
      name: "Bhutan",
      text: "不丹",
    },
    {
      value: 591,
      name: "Bolivia",
      text: "玻利維亞",
    },
    {
      value: 55,
      name: "Brazil",
      text: "巴西",
    },
    {
      value: 855,
      name: "Cambodia",
      text: "柬埔寨",
    },
    {
      value: 237,
      name: "Cameroon",
      text: "喀麥隆",
    },
    {
      value: 86,
      name: "China",
      text: "中國",
    },
    {
      value: 1264,
      name: "Anguilla",
      text: "安圭拉",
    },
    {
      value: 1268,
      name: "Antigua and Barbuda",
      text: "安地卡及巴布達",
    },
    {
      value: 297,
      name: "Aruba",
      text: "阿魯巴",
    },
    {
      value: 1441,
      name: "Bermuda",
      text: "百慕達",
    },
    {
      value: 1767,
      name: "Dominica",
      text: "多米尼克",
    },
    {
      value: 1809,
      name: "Dominican Republic",
      text: "多明尼加共和國",
    },
    {
      value: 1473,
      name: "Grenada",
      text: "格瑞那達",
    },
    {
      value: 1758,
      name: "Saint Lucia",
      text: "聖露西亞",
    },
    {
      value: 57,
      name: "Colombia",
      text: "哥倫比亞",
    },
    {
      value: 243,
      name: "Congo",
      text: "剛果",
    },
    {
      value: 41,
      name: "Switzerland",
      text: "瑞士",
    },
    {
      value: 49,
      name: "Germany",
      text: "德國",
    },
    {
      value: 45,
      name: "Denmark",
      text: "丹麥",
    },
    {
      value: 20,
      name: "Egypt",
      text: "埃及",
    },
    {
      value: 34,
      name: "Spain",
      text: "西班牙",
    },
    {
      value: 503,
      name: "El Salvador",
      text: "薩爾瓦多",
    },
    {
      value: 358,
      name: "Finland",
      text: "芬蘭",
    },
    {
      value: 679,
      name: "Fiji",
      text: "斐濟",
    },
    {
      value: 33,
      name: "France",
      text: "法國",
    },
    {
      value: 995,
      name: "Georgia",
      text: "喬治亞",
    },
    {
      value: 233,
      name: "Ghana",
      text: "迦納",
    },
    {
      value: 30,
      name: "Greece",
      text: "希臘",
    },
    {
      value: 502,
      name: "Guatemala",
      text: "瓜地馬拉",
    },
    {
      value: 967,
      name: "Guyana",
      text: "蓋亞那",
    },
    {
      value: 509,
      name: "Haiti",
      text: "海地",
    },
    {
      value: 504,
      name: "Honduras",
      text: "宏都拉斯",
    },
    {
      value: 91,
      name: "India",
      text: "印度",
    },
    {
      value: 354,
      name: "Iceland",
      text: "冰島",
    },
    {
      value: 62,
      name: "Indonesia",
      text: "印尼",
    },
    {
      value: 964,
      name: "Iraq",
      text: "伊拉克",
    },
    {
      value: 353,
      name: "Ireland",
      text: "愛爾蘭",
    },
    {
      value: 39,
      name: "Italy",
      text: "義大利",
    },
    {
      value: 1876,
      name: "Jamaica",
      text: "牙買加",
    },
    {
      value: 962,
      name: "Jordan",
      text: "約旦",
    },
    {
      value: 7,
      name: "Kazakhstan",
      text: "哈薩克",
    },
    {
      value: 254,
      name: "Kenya",
      text: "肯亞",
    },
    {
      value: 965,
      name: "Kuwait",
      text: "科威特",
    },
    {
      value: 352,
      name: "Luxembourg",
      text: "盧森堡",
    },
    {
      value: 389,
      name: "Macedonia",
      text: "馬其頓",
    },
    {
      value: 261,
      name: "Madagascar",
      text: "馬達加斯加",
    },
    {
      value: 60,
      name: "Malaysia",
      text: "馬來西亞",
    },
    {
      value: 960,
      name: "Maldives",
      text: "馬爾地夫",
    },
    {
      value: 52,
      name: "Mexico",
      text: "墨西哥",
    },
    {
      value: 212,
      name: "Morocco",
      text: "摩洛哥",
    },
    {
      value: 47,
      name: "Norway",
      text: "挪威",
    },
    {
      value: 674,
      name: "Nauru",
      text: "諾魯",
    },
    {
      value: 64,
      name: "New Zealand",
      text: "紐西蘭",
    },
    {
      value: 505,
      name: "Nicaragua",
      text: "尼加拉瓜",
    },
    {
      value: 234,
      name: "Nigeria",
      text: "奈及利亞",
    },
    {
      value: 92,
      name: "Pakistan",
      text: "巴基斯坦",
    },
    {
      value: 507,
      name: "Panama",
      text: "巴拿馬",
    },
    {
      value: 675,
      name: "Papua New Guinea",
      text: "巴布亞紐幾內亞",
    },
    {
      value: 351,
      name: "Portuguese",
      text: "葡萄牙",
    },
    {
      value: 595,
      name: "Paraguay",
      text: "巴拉圭",
    },
    {
      value: 40,
      name: "Romania",
      text: "羅馬尼亞",
    },
    {
      value: 7,
      name: "Russia",
      text: "俄羅斯",
    },
    {
      value: 250,
      name: "Rwanda",
      text: "盧安達",
    },
    {
      value: 966,
      name: "Saudi Arabia",
      text: "沙烏地阿拉伯",
    },
    {
      value: 381,
      name: "Syria",
      text: "敘利亞",
    },
    {
      value: 248,
      name: "Seychelles",
      text: "塞席爾",
    },
    {
      value: 94,
      name: "Sri Lanka",
      text: "斯里蘭卡",
    },
    {
      value: 65,
      name: "Singapore",
      text: "新加坡",
    },
    {
      value: 249,
      name: "Sudan",
      text: "蘇丹",
    },
    {
      value: 46,
      name: "Sweden",
      text: "瑞典",
    },
    {
      value: 66,
      name: "Thailand",
      text: "泰國",
    },
    {
      value: 676,
      name: "Tonga",
      text: "東加",
    },
    {
      value: 90,
      name: "Turkey",
      text: "土耳其",
    },
    {
      value: 256,
      name: "Uganda",
      text: "烏干達",
    },
    {
      value: 380,
      name: "Ukraine",
      text: "烏克蘭",
    },
    {
      value: 971,
      name: "United Arab Emirates",
      text: "阿拉伯聯合大公國",
    },
    {
      value: 598,
      name: "Uruguay",
      text: "烏拉圭",
    },
    {
      value: 998,
      name: "Uzbekistan",
      text: "烏茲別克",
    },
    {
      value: 58,
      name: "Venezuela",
      text: "委內瑞拉",
    },
    {
      value: 967,
      name: "Yemen",
      text: "葉門",
    },
  ];
}
