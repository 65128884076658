export default {
  set(member) {
    if (member) {
      window.localStorage["member"] = JSON.stringify({
        Name: member.Name,
        Cellphone: member.Cellphone,
      });
      if (member.Token) window.localStorage["authToken"] = member.Token;
    }
  },
  getToken() {
    return window.localStorage["authToken"];
  },
  get() {
    let member = window.localStorage["member"];
    if (!member) {
      return undefined;
    }
    return JSON.parse(member);
  },
  remove() {
    localStorage.removeItem("member");
  },
  clearId() {
    window.localStorage.removeItem("authToken");
  },
};
