import { httpPost } from "@/requests/http";

const url = process.env.VUE_APP_API_URL;

export default {
  getRegisterVerify(dTO) {
    return httpPost(`${url}/register/phone-verify`, dTO);
  },
  getForgetPasswordVerify(dTO) {
    return httpPost(`${url}/forget-password/phone-verify`, dTO);
  },
};
