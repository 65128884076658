import { httpGetWithKey } from "@/requests/http";

let path = "/historyorders";

export default {
  getAll() {
    return httpGetWithKey(path);
  },
  get(id) {
    return httpGetWithKey(`${path}/${id}`);
  },
  getInStore(salTempId) {
    return httpGetWithKey(`${path}/${salTempId}/instore`);
  },
};
