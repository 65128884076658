import { httpGet, httpPost } from "@/requests/http";

import cookie from "../../cookie";
import swal from "vue-sweetalert2";

let path = "/stores";
const url = process.env.VUE_APP_API_URL;

export default {
  get() {
    return httpGet(`${url}${path}`);
  },
  getStatus(id, dTO) {
    return new Promise((resolve, reject) => {
      httpGet(`${url}${path}/${id}/status`, dTO)
        .then((data) => {
          if (!cookie.cart.checkStore(id, data.MenuLastUpdateTime)) {
            swal("菜單已更新，將清空您的購物車 !");
          }
          cookie.store.set(id, data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getImg(id) {
    return httpGet(`${url}${path}/${id}/image`, null, {
      responseType: "blob",
    });
  },
  getOrdersStatus(id, item) {
    return httpPost(`${url}${path}/${id}/ordersStatus`, item);
  },
};
