<template>
  <div class="login">
    <div class="fix-header">
      <a class="fix-header-back-icon" @click="back">
        <i class="fas fa-chevron-left"></i>
      </a>
      <p class="fix-header-title">{{ $t("forgotPassword.forgotpassword") }}</p>
    </div>
    <form class="content" @submit.prevent="saved">
      <div class="input-button-group mb-2">
        <div class="country-code-container" @click="openCountryList">
          <input
            class="country-code-input"
            aria-hidden="true"
            v-model="currentCountryCode"
          />
          <div class="country-code-text">+{{ currentCountryCode }}</div>
          <i class="fas fa-caret-down"></i>
          <ul class="country-list">
            <li
              v-for="countryCode in countryCodes"
              :key="countryCode.value"
              :data-value="countryCode.value"
              class="country"
              :class="[countryCode.value == 886 ? 'active' : '']"
              @click="selectCountry"
            >
              {{ `${countryCode.text}(+${countryCode.value})` }}
            </li>
          </ul>
        </div>
        <input
          class="input-1"
          type="tel"
          v-model="register.Cellphone"
          :placeholder="$t('forgotPassword.phonenumber')"
          required
        />
        <button
          type="button"
          @click="getVerifyCode"
          style="width: 130px; padding: 0"
          v-if="time == 0"
        >
          {{ $t("forgotPassword.getverifycode") }}
        </button>
        <button
          type="button"
          class="btn-danger"
          style="width: 130px; padding: 0"
          v-else
        >
          {{ time }}{{ $t("forgotPassword.retry") }}
        </button>
      </div>
      <!-- <p v-if="time > 0">(若未收到驗證碼請{{ time }}後重試)</p> -->

      <input
        class="input-1 mb-2"
        type="text"
        v-model="register.VerifyCode"
        :placeholder="$t('forgotPassword.verifycode')"
        required
      />
      <div class="relative mb-2">
        <input
          class="input-1 inline-block m-0"
          :type="passwordType"
          v-model="register.Password"
          :placeholder="$t('forgotPassword.newpassword')"
          required
        />
        <a class="btn-right" @click="passwordEyeClick(false)" v-if="isPassword">
          <div>
            <i class="fas fa-eye-slash"></i>
          </div>
        </a>
        <a class="btn-right" @click="passwordEyeClick(false)" v-else>
          <div><i class="fas fa-eye"></i></div
        ></a>
      </div>

      <div class="relative mb-2">
        <input
          class="input-1 inline-block m-0"
          :type="passwordCheckType"
          v-model="register.CheckPassword"
          :placeholder="$t('forgotPassword.enterpasswordagain')"
          required
        />
        <a
          class="btn-right"
          @click="passwordEyeClick(true)"
          v-if="isPasswordCheck"
        >
          <div>
            <i class="fas fa-eye-slash"></i>
          </div>
        </a>
        <a class="btn-right" @click="passwordEyeClick(true)" v-else>
          <div><i class="fas fa-eye"></i></div
        ></a>
      </div>

      <span
        for="checkpassword"
        class="form-message-alert"
        v-if="isPasswordError"
        >{{ $t("forgotPassword.pleaseentersamepassword") }}</span
      >
      <button class="btn btn-full btn-border my-3" type="submit">
        {{ $t("forgotPassword.save") }}
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      register: {},
      timer: null,
      time: 0,
      passwordType: "password",
      passwordCheckType: "password",
      countryCodes: this.$enum.countryCodeType.getAll(),
      currentCountryCode: 886,
    };
  },
  computed: {
    isPasswordError() {
      return this.register.Password != this.register.CheckPassword;
    },
    isPassword() {
      return this.passwordType == "password";
    },
    isPasswordCheck() {
      return this.passwordCheckType == "password";
    },
  },
  methods: {
    addCancelArea() {
      const cancelArea = document.querySelector(".cancel-area");
      cancelArea.style.display = "block";
      document.addEventListener("click", this.removeCancelArea);
    },
    removeCancelArea() {
      const countryList = document.querySelector(".country-list");
      const cancelArea = document.querySelector(".cancel-area");
      countryList.classList.remove("active");
      cancelArea.style.display = "none";
      document.removeEventListener("click", this.removeCancelArea);
    },
    openCountryList(e) {
      e.stopPropagation();
      const countryList = document.querySelector(".country-list");
      const cancelArea = document.querySelector(".cancel-area");
      countryList.classList.add("active");
      cancelArea.style.display = "block";
      this.addCancelArea();
    },
    selectCountry(e) {
      e.stopPropagation();
      const countries = document.querySelectorAll(".country");
      countries.forEach((c) => {
        c.classList.remove("active");
      });
      this.currentCountryCode = e.target.dataset.value;
      e.target.classList.add("active");
      this.removeCancelArea();
    },
    saved() {
      if (this.register.Password != this.register.CheckPassword) {
        this.$swal(this.$t("forgotPassword.pleaseentersamepassword"));
        return;
      }
      this.register.CountryCode = this.currentCountryCode;
      var ob = { ...this.register };
      ob.Password = this.$utils.crypto.encrypt(ob.Password);
      ob.CheckPassword = this.$utils.crypto.encrypt(ob.CheckPassword);
      const vm = this;
      this.$api.members
        .forgetPassword(ob)
        .then(() => {
          vm.goLogin();
        })
        .catch((error) => vm.$swal(error.data.Message));
    },
    goLogin() {
      this.$router.push({
        name: "Login",
        params: { keyid: this.$route.params.keyid },
      });
    },
    back() {
      this.$router.go(-1);
    },
    getVerifyCode() {
      if (this.timer != null) {
        this.$swal(this.$t("forgotPassword.pleasetryagainlater"));
        return;
      }
      if (!this.register.Cellphone) {
        this.$swal(this.$t("forgotPassword.pleaseenterphonenumber"));
        return;
      }
      const vm = this;
      this.$api.phones
        .getForgetPasswordVerify(this.register)
        .then(() => {
          vm.time = 300;
          vm.timer = setInterval(vm.timeDown, 1000);
          vm.$swal(this.$t("forgotPassword.pleasecheckyourmobilemessage"));
        })
        .catch((error) => vm.$swal(error.data.Message));
    },
    timeDown() {
      this.time = this.time - 1;
      if (this.time <= 0) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    passwordEyeClick(isCheck) {
      if (isCheck) {
        if (this.isPasswordCheck) {
          this.passwordCheckType = "text";
        } else {
          this.passwordCheckType = "password";
        }
      } else {
        if (this.isPassword) {
          this.passwordType = "text";
        } else {
          this.passwordType = "password";
        }
      }
    },
  },
  beforeCreate() {
    const token = this.$cookie.member.getToken();
    let routeData = this.$func.getRoute();
    if (token) {
      this.$router.push({
        name: routeData.InStore + "Menu",
        params: {
          routeid: routeData.RouteId,
        },
      });
    }
  },
  created() {
    this.$store.dispatch("updateLoading", false);
    window.scrollTo(0, 0);
  },
};
</script>
