<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("updateLoading", true);
    let url = new URL(decodeURIComponent(window.location.href));
    let param = url.searchParams;
    if (this.$liff.isInClient()) {
      this.$liff
        .init({
          liffId: this.$cookie.store.get(this.$store.state.keyId).LineLiffId,
        })
        .then(() => {
          this.$liff.getProfile().then((profile) => {
            this.$store.dispatch("setLineUserId", profile.userId);
            if (param.has("path")) {
              this.$router.push({
                name: param.get("path"),
                params: { keyid: this.$store.state.keyId },
              });
            }
          });
        });
    } else {
      if (param.has("path")) {
        this.$router.push({
          name: param.get("path"),
          params: { keyid: this.$store.state.keyId },
        });
      }
    }
  },
};
</script>
