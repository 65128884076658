import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "Confirming",
      text: `${i18n.t("historyOrder.confirming")}`,
    },
    {
      value: 1,
      name: "Accepted",
      text: `${i18n.t("historyOrder.accepted")}`,
    },
    {
      value: 2,
      name: "Rejected",
      text: `${i18n.t("historyOrder.rejected")}`,
    },
    {
      value: 3,
      name: "Completed",
      text: `${i18n.t("historyOrder.completed")}`,
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
