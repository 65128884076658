// http.js
import axios from "axios";
import router from "../router";
import store from "../store";
import cookie from "../cookie";
axios.defaults.timeout = 180000;
// axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    if (localStorage.authToken) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "authToken"
      )}`;
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response != undefined && response.data != undefined)
        return Promise.resolve(response.data);
      else return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          cookie.member.remove();
          cookie.member.clearId();
          console.log("401 http.js prepare to replace");
          router.replace({
            name: "Login",
            params: {
              keyid: localStorage.getItem("currentStore"),
            },
          });
      }
      return Promise.reject(error.response);
    } else return Promise.reject(error);
  }
);

export function httpGet(url, params, config) {
  if (config) config.params = params;
  else config = { params };
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .get(url, config)
      .then((response) => {
        if (response != undefined && response.data != undefined)
          resolve(response.data);
        else resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpPost(url, params, config) {
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .post(url, params, config)
      .then((response) => {
        store.dispatch("updateLoading", false);
        if (response != undefined && response.data != undefined)
          resolve(response.data);
        else resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpPut(url, params) {
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .put(url, params)
      .then((response) => {
        store.dispatch("updateLoading", false);
        resolve(response.data);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpPatch(url, params) {
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .patch(url, params)
      .then((response) => {
        store.dispatch("updateLoading", false);
        if (response != undefined && response.data != undefined)
          resolve(response.data);
        else resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpDelete(url, params) {
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .delete(url, { params })
      .then((response) => {
        store.dispatch("updateLoading", false);
        if (response != undefined && response.data != undefined)
          resolve(response.data);
        else resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpGetWithKey(url, params, config) {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  let keyid = window.location.pathname.replace("liff/", "").substring(1, 13);
  let storeStr = window.localStorage[`store.${keyid}`];
  let deviceId = "";
  if (storeStr) {
    deviceId = JSON.parse(storeStr).DeviceId;
  } else {
    deviceId = store.state.deviceId;
  }
  url = deviceId + url;
  if (config) config.params = params;
  else config = { params };
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpPostWithKey(url, params, config) {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  let keyid = window.location.pathname.substring(1, 13);
  let storeStr = window.localStorage[`store.${keyid}`];
  let deviceId = "";
  if (storeStr) {
    deviceId = JSON.parse(storeStr).DeviceId;
  } else {
    deviceId = store.state.deviceId;
  }
  url = deviceId + url;
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .post(url, params, config)
      .then((response) => {
        store.dispatch("updateLoading", false);
        resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpPutWithKey(url, params) {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  let keyid = window.location.pathname.substring(1, 13);
  let storeStr = window.localStorage[`store.${keyid}`];
  let deviceId = "";
  if (storeStr) {
    deviceId = JSON.parse(storeStr).DeviceId;
  } else {
    deviceId = store.state.deviceId;
  }
  url = deviceId + url;
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .put(url, params)
      .then((response) => {
        store.dispatch("updateLoading", false);
        resolve(response.data);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpPatchWithKey(url, params) {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  let keyid = window.location.pathname.substring(1, 13);
  let storeStr = window.localStorage[`store.${keyid}`];
  let deviceId = "";
  if (storeStr) {
    deviceId = JSON.parse(storeStr).DeviceId;
  } else {
    deviceId = store.state.deviceId;
  }
  url = deviceId + url;
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .patch(url, params)
      .then((response) => {
        store.dispatch("updateLoading", false);
        resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpDeleteWithKey(url, params) {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  let keyid = window.location.pathname.substring(1, 13);
  let storeStr = window.localStorage[`store.${keyid}`];
  let deviceId = "";
  if (storeStr) {
    deviceId = JSON.parse(storeStr).DeviceId;
  } else {
    deviceId = store.state.deviceId;
  }
  url = deviceId + url;
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .delete(url, { params })
      .then((response) => {
        store.dispatch("updateLoading", false);
        resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}
