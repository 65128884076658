<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      store: {},
    };
  },
  mounted() {
    const sixHours = 6 * 60 * 60 * 1000;
    const salTempId = localStorage.getItem("salTempId");
    this.store = this.$cookie.store.get(this.$route.params.keyid);
    const tableMapId = this.$cookie.table.get();
    const tableStartTime = localStorage.getItem("tableStartTime");
    const nowTime = new Date().getTime();
    if (
      !(
        salTempId &&
        tableMapId == this.$route.params.routeid &&
        nowTime - tableStartTime < sixHours
      )
    ) {
      localStorage.removeItem("salTempId");
      this.$cookie.table.remove();
      localStorage.removeItem("tableStartTime");
      this.$router.replace({
        name: "InStoreTableMenu",
        routeid: this.$route.params.routeid,
      });
    } else {
      if (salTempId) {
        this.$api.inStore
          .getLastTable(
            this.store.DeviceId,
            this.$route.params.routeid,
            salTempId
          )
          .then(() => {
            this.$router.replace({
              name: "InStoreTableMenu",
              routeid: this.$route.params.routeid,
            });
          })
          .catch(() => {
            localStorage.removeItem("salTempId");
            this.$cookie.table.remove();
            localStorage.removeItem("tableStartTime");
            this.$router.replace({
              name: "InStoreTableMenu",
              routeid: this.$route.params.routeid,
            });
          });
      } else {
        this.$router.replace({
          name: "InStoreTableMenu",
          routeid: this.$route.params.routeid,
        });
      }
    }
  },
};
</script>
