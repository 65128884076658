export function getAll() {
  return [
    {
      value: 0,
      text: "等候中",
    },
    {
      value: 1,
      text: "製作中",
    },
    {
      value: 2,
      text: "已被拒絕",
    },
    {
      value: 3,
      text: "完成",
    },
  ];
}
