const kr = {
  menu: {
    openinghours: "영업 시간",
    login: "로그인",
    joinstore: "회원 가입",
    open: "영업 중",
    closed: "영업 종료",
    preorder: "사전 주문",
    left: "남은 ",
    soldout: "품절",
    cart: "장바구니",
    cartitem: " 개",
    pleasechooseyourmeal: "메뉴를 선택해 주세요",
    closedday: "정기 휴무일",
    today: "오늘",
    stocklimitreached: "재고 한도에 도달했습니다",
    closedtoday: "오늘은 문을 닫았습니다",
    ordertimehaspassed: "마감 시간이 지났습니다",
    thistimeslotisfullybooked: "이 시간대는 만석입니다",
    minuteslater: "분 후",
    hourslater: "시간 후",
    orderedmeals: "주문된 항목",
    addmoremeals: "추가 주문",
  },
  week: {
    sunday: "일요일",
    monday: "월요일",
    tuesday: "화요일",
    wednesday: "수요일",
    thursday: "목요일",
    friday: "금요일",
    saturday: "토요일",
  },
  takeAwayTime: {
    expectedpickuptime: "예상 수령 시간",
    pickupdate: "수령 날짜",
    pickuptime: "수령 시간",
    otherdates: "기타 날짜",
    confirm: "확인",
    today: "오늘",
    orderfull: "완료",
    isorderfull: " (이 시간대의 주문이 완료되었습니다)",
  },
  checkout: {
    cart: "장바구니",
    expectedpickuptime: "예상 수령 시간",
    cancel: "주문",
    notice: "주의 사항",
    orderdetails: "주문 세부 사항",
    total: "총 금액",
    memo: "주석",
    member: "회원",
    name: "이름",
    phonenumber: "모바일 번호",
    receipt: "영수증",
    uniformnumber: "통일 사업자 번호",
    vehiclenumber: "바코드 식별 번호",
    submitorder: "주문하다",
    today: "오늘",
    closedtoday: "오늘은 문을 닫았습니다",
    ordertimehaspassed: "마감 시간이 지났습니다",
    allergiesoringredients:
      "알레르기나 추가하고 싶지 않은 재료가 있으면 알려주세요.",
    vehiclenumberisincorrect: "바코드 식별 번호 형식이 올바르지 않습니다.",
    areyousuretosubmittheorder: "주문을 제출하시겠습니까 ?",
    businesshourshavechanged:
      "영업 시간이 변경되었습니다. 시간을 다시 확인해주세요.",
    areyousureyouwanttodeletethis: "정말 삭제하시겠습니까 ?",
    areyousureyouwanttodeletetheentireorder: "주문 전체를 삭제하시겠습니까 ?",
    thedatehasbeenupdated: "날짜가 업데이트되었습니다.",
    minuteslater: "분 후",
    hourslater: "시간 후",
    continueordering: "주문 계속하기",
  },
  login: {
    login: "로그인",
    phonenumber: "모바일 번호",
    password: "비밀번호",
    forgotpassword: "비밀번호를 잊으셨나요",
    register: "가입하기",
  },
  join: {
    join: "참가",
    joinstore: "회원 가입",
    alreadyjoin: "이미 로그인되어 있습니다. 바로 참여하실 수 있습니다.",
  },
  forgotPassword: {
    forgotpassword: "비밀번호를 잊으셨나요",
    phonenumber: "모바일 번호",
    getverifycode: "인증 코드를 받기",
    retry: "s 다시 시도",
    verifycode: "인증 코드",
    newpassword: "새 비밀번호",
    enterpasswordagain: "비밀번호 다시 입력",
    pleaseentersamepassword: "동일한 비밀번호를 입력하세요",
    save: "저장",
    pleasetryagainlater: "나중에 다시 시도해 주세요",
    pleaseenterphonenumber: "전화번호를 입력하세요",
    pleasecheckyourmobilemessage: "휴대폰 메시지를 확인하세요",
  },
  member: {
    profile: "프로필",
    historyorder: "주문 내역",
    changepassword: "비밀번호 변경",
    reportissue: "문제 신고",
    logout: "로그아웃",
    name: "이름",
    birthday: "생일",
    email: "이메일",
    address: "주소",
    link: "계정을 연동하다",
    unlink: "계정 연동을 해제하다",
    notlink: "아직 연동되지 않음",
    save: "저장",
    changesuccess: "변경이 성공적으로 완료되었습니다",
    areyousureyouwanttounlink: "정말 연결을 해제하시겠습니까 ?",
    unlinkseccessful: "연결 해제가 성공적으로 완료되었습니다",
  },
  historyOrder: {
    historyorder: "주문 내역",
    ordernumber: "주문 번호",
    createtime: "주문 시간",
    eattype: "식사 선택",
    reservetime: "픽업 시간",
    checkouttime: "결제 시간",
    memo: "주석",
    total: "총 금액",
    confirming: "확인 중",
    accepted: "수락됨",
    rejected: "거절됨",
    completed: "완료됨",
  },
  order: {
    required: "필수",
    available: "선택 가능",
    exceedsselectionlimit: "선택 한도를 초과했습니다",
    minqtyreached: "최소 단위에 도달했습니다",
    ok: "확인",
    mealsetsnotselected: "세트 메뉴가 선택되지 않았습니다",
    flavorsnotselected: "맛 선택이 완료되지 않았습니다",
    mealsetsover: "세트 메뉴가 선택 한도를 초과했습니다",
    suretoreset: "이 항목이 초기화됩니다. 계속하시겠습니까 ?",
    modifyflavors: "맛 수정",
    save: "저장",
    soldout: "품절",
    memo: "주석",
  },
  trade: {
    orderinformation: "주문 정보",
    orderdetail: "주문 내역",
    ordernumber: "주문 번호",
    confirming: "확인 중",
    accepted: "수락됨",
    rejected: "거절됨",
    completed: "완료됨",
    tablenumber: "테이블 번호",
    tablenum: "테이블 번호",
    name: "이름",
    memo: "주석",
    total: "총 금액",
    receiptinformation: "영수증 정보",
    uniformnumber: "통일 사업자 번호",
    vehiclenumber: "바코드 식별 번호",
    backtomenu: "메뉴로 돌아가기",
    rejectreason: "거절 사유",
    completetime: "완료 시간",
    createtime: "주문 시간",
    reservetime: "픽업 시간",
    member: "회원",
    changelogs: "변경 기록",
  },
  changePassword: {
    changepassword: "비밀번호 변경",
    oldpassword: "이전 비밀번호",
    newpassword: "새 비밀번호",
    enterpasswordagain: "비밀번호 다시 입력",
    pleaseentersamepassword: "동일한 비밀번호를 입력하세요",
    changesuccess: "변경이 성공적으로 완료되었습니다",
    save: "저장",
  },
  register: {
    memberregister: "회원 가입",
    name: "이름",
    phonenumber: "모바일 번호",
    verifycode: "인증 코드",
    password: "비밀번호",
    enterpasswordagain: "비밀번호 다시 입력",
    pleaseentersamepassword: "동일한 비밀번호를 입력하세요",
    getverifycode: "인증 코드를 받기",
    retry: "s 다시 시도",
    register: "가입하기",
    alreadyhaveanaccount: "이미 계정이 있습니다",
    pleasetryagainlater: "나중에 다시 시도해 주세요",
    pleaseenterphonenumber: "전화번호를 입력하세요",
    pleasecheckyourmobilemessage: "휴대폰 메시지를 확인하세요",
  },
  feedback: {
    reportissue: "문제 신고",
    pleaseentertheissueyouwanttoreport: "신고할 문제를 입력하세요",
    submit: "제출",
    thanksforyourreport: "신고해 주셔서 감사합니다",
  },
  error: {
    sorrymessage:
      "죄송합니다, 찾으시는 페이지가 존재하지 않거나 삭제되었습니다",
    goback: "이전 페이지로 돌아가기",
    urlexpired: "이 URL은 만료되었습니다.",
  },
};

export default kr;
